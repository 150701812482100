@import "../../variables.scss";

.editOrderDevicePageContainer{
  position: relative;
}

.editOrderNoResults{
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  font-family: "DINProRegular", Arial, sans-serif;
  font-size: 1.5rem;
  color: $grey;
}