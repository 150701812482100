
.servicedDevicePageTitle{
  font-family: "DINProRegular", Arial, sans-serif;
  margin: 0 30px 10px 30px;
}

.stickyDeviceListHeader {
  top: 45px;
  padding-top: 25px;
}

:global(.athenaeum-page-container-page .athenaeum-page-container-pageRow.athenaeum-page-container-alertRow) {
  margin-bottom: 20px !important;
}