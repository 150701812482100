@import "../../variables";

.additionalExpensePanel {
  .addAdditionalExpenseButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    .addAdditionalExpenseButton {
      max-width: 33rem !important;
    }
  }

  .additionalExpenseItemsContainer {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    &.desktop {
      text-align: -webkit-center;
      display: inline-block;
    }

    .additionalExpenseItem {
      display: block;
      box-shadow: rgba(99, 99, 99, 0.5) 0 2px 8px 1px;
      border: 1px solid $renta-border;
      border-radius: 3px;
      margin-bottom: $baseline*2;
      background: white;
      
      &.bottomSpace {
        padding-bottom: $baseline;
      }

      &.invalid {
        box-shadow: 0 0 8px #ff0000;
      }

      &.desktop {
        max-width: 50%;
      }

      .itemIndex {
        height: 100%;
        display: flex !important;
        float: left;
        margin-top: 15px;
        margin-left: 15px;
        color: $text-black;
        border: solid 1px $grey-border;
        box-shadow: $renta-shadow;
        border-radius: $radius;
        padding: 6px 8px;
      }

      .removeButton {
        margin-top: 15px;
        margin-right: 15px;
        display: flex !important;
        float: right;
      }

      .textInput {
        > div {
          justify-content: center;

          > label {
            margin-bottom: 0 !important;
          }
        }

        padding-left: 15px;
        padding-right: 15px;

        &.desktop {
          width: 100%;
        }
      }

      .bottomIndent {
        padding-bottom: 15px;
      }
    }
  }
}