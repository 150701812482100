@import "../../variables";

.instruction {
  
  .photoContainer {
    
    display: flex;    
    
    > div:last-child {
      display: flex;
      align-items: center;
      
      span {
        font-size: 1.0em;
      }
    }

    i {
      padding: 2*$baseline;
      margin: $baseline;
      color: $grey;
      border-radius: 3px;
      border: 1px solid $orange;
    }
    
  }
  
}