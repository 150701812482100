@import "../../variables";

.textInputWrapper{
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
}

.microphoneButton{
  padding-left: 10px;
  z-index: 1000;
  font-size: 1.5rem;
  color: $grey;
  cursor: pointer;
}

.textInputTooltip{
  position: absolute;
  width: 100%;
  background: #f5f2f2;
  padding: 5px;
  border-bottom: 0.25px solid #707070;
  border-left: 0.25px solid #707070;
  border-right: 0.25px solid #707070;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  bottom: -32px;
}

.stopButton{
  padding-left: 10px;
  z-index: 1000;
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
}