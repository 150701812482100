@import "../../variables";

.errorCodePage{
  position: relative;
}

.tooltip{
  position: absolute;
  right: 10px;
  top: 5px;

  @media screen and (max-width: $break-md)  {
    top: 80px;
  }
}
