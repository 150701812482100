@import "../../variables";

.deviceService {

    > div > div > div {
        margin-bottom: -5px !important;
    }

    .faultsContainer {
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .faultChecks {
            margin-top: 1rem !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;

            > div > h5 {
                margin-bottom: 0;
            }
        }
    }

    .sectionContainer {
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        :global {
            .row {
                &:first-of-type {
                    padding-top: 0;
                }
            }
        }

        > div > div > h5 {
            margin-bottom: 0;
        }

        .serviceItem {
            margin-top: 1rem !important;
            margin-bottom: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .date {
                width: 100%;

                :global {
                    .react-datepicker-wrapper {
                        width: 100%;
                    }
                }
            }

            .comment {
                width: 100%;
                margin-top: $baseline;
                border: 0.25px solid $dark-gray;
                border-radius: 5px;
                font-size: 12px;
                font-family: "DINProRegular", Arial, sans-serif;
            }

            .label {
                height: 38px !important;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 4px;
                font-size: 1rem !important;
                color: #495057 !important;

                i {
                    order: 2;
                }
            }

            .checkbox {
                margin-bottom: 1rem !important;
            }
        }
    }

    .saveButton {
        margin-top: 1rem !important;
    }
}

.commentWrapper{

    margin: 15px 15px 25px;

    padding: 8px 15px;
    
    @media screen and (max-width: $break-md) {
        margin: 1rem 0 0;

        padding: 0;
    }
}