@import "../../variables";

.phase {
  position: relative;
  border-radius: 6px;
  opacity: 1;
  padding: 16px;
  border: 0.25px solid $dark-gray;
  font-family: "DINProLight", Arial, sans-serif;
  width: 80%;
  background: #FFFFFF;

  @media only screen and (max-width: 760px) {
    width: 100%;
  }

  .phaseTitle {
    font-size: 16px;
    font-family: "DINProBold", Arial, sans-serif;
    margin-bottom: 10px;
    margin-left: 5px;

  }

  .editButton {
    position: absolute;
    font-size: 14px !important;
    width: 111px;
    height: 30px;
    font-family: "DINProMedium", Arial, sans-serif;
    border-radius: 6px;
    right: 16px;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.tableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.summaryItem {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 40px 40px;

  .itemDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  p {
    margin: 0 !important;
  }

  .title {
    font-size: 12px;
    font-family: "DINProBold", Arial, sans-serif;
    vertical-align: middle;
    width: 100%;
    line-height: 20px;
    word-break: break-word;
  }
}

.statusBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid $dark-gray;


  &.failed {
    background-color: $orange;
  }

  &.okay {
    background-color: $green;
  }
  
  &.skipped{
    background-color: $dark-gray;
  }
}


.picture {
  position: relative;
  height: 100px;
  width: 100%;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: white !important;
  margin-bottom: 16px;
}

.stepContainer {
  display: grid;
  grid-template-rows: 1fr auto;
  border: 1px solid $dark-gray;
  border-radius: 6px;
  padding: 13px 13px 13px 23px;
  font-family: "DINProLight", Arial, sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  position: relative;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkMarkIcon {
  color: $green;
}

.notCompletedIcon {
  color: $dark-gray;
  font-size: 1.25rem;
}

.checkFailed {
  color: $orange;
}

.additionalExpensesIcon {
  padding-top: 4px;
  justify-content: center;
  color: $green;

  &.invalid {
    color: red;
  }
}

.invalidAdditionalExpensesExclMark {
  color: red;
  padding-left: 4px;
  padding-top: 6px;
}

.expensesCount {
  padding-top: 4px;
}

.progressBar::-moz-progress-bar
{
  /* Gradient background with Stripes */
  background-image:
          -moz-linear-gradient( 135deg,
                  transparent,
                  transparent 33%,
                  rgba(0,0,0,.1) 33%,
                  rgba(0,0,0,.1) 66%,
                  transparent 66%),
          -moz-linear-gradient( top,
                  rgba(255, 255, 255, .25),
                  rgba(0,0,0,.2)),
          -moz-linear-gradient( left, #09c, #ff0);
}

progress:not(value) {
}


progress[value] {
  appearance: none;

  border: none;

  width: 100%;
  height: 10px;

  background-color: whiteSmoke;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .5) inset;

  position: relative;
  margin: 0 0 1.5em;
}


progress[value]::-webkit-progress-bar {
  background-color: whiteSmoke;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .5) inset;
}

progress[value]::-webkit-progress-value {
  position: relative;

  background-size: 35px 20px, 100% 100%, 100% 100%;
  border-radius: 3px;

  animation: animate-stripes 5s linear infinite;
}

@keyframes animate-stripes { 100% { background-position: -100px 0; } }


progress[value]::-webkit-progress-value:after {
  content: '';
  position: absolute;

  width: 5px;
  height: 5px;
  top: 7px;
  right: 7px;

  background-color: white;
  border-radius: 100%;
}

.progressBar::-webkit-progress-value {
  background-color: $green;
}

.progressBarYellow::-webkit-progress-value {
  background-color: $yellow;
}

.progressInfo {
  margin: 5px;
}

.advanceCheckWrapper {
  padding: 5px 0;
}

.pictureWrapper{
  margin-bottom: 10px;
}