@import "../../../../variables.scss";

.navigationMenu{
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  background: white;
  padding: 10px;
  box-shadow: 0 2px 4px #222;
  gap: 10px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
}