@import "../../variables";

.checkWrapper{
  border: 1px solid $dark-gray;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
}

.titleWrapper{
  font-family: "DINProBold", Arial, sans-serif;
  display: flex;
  position: relative;
}

.extendedIcon{
  position: absolute;
  right: 5px;
}

.checkIcon{
  margin-right: 10px;
}

.ok{
  color: $green;
}

.notOk{
  color: $orange;
}

.notCompleted{
  color: $dark-gray;;
}