@import "../../variables";

.container {


  table {

    * {
      border: none !important;
      border-collapse: collapse !important;
    }

    tbody {

      tr:nth-child(odd){
        background-color: $lighter-gray;
        &:hover{
          background-color: $lighter-gray;
        }
      }

      tr:nth-child(3) {
        td {
          background-color: $white;
        }
      }

      tr:nth-child(7) {
        td {
          background-color: $white;
        }
      }

    }

    thead {
      display: none;
    }

  }

  .telematicsDataTable {
    border: none !important;
    border-collapse: collapse !important;

    .valueColumn {

      display: flex;
      div {
        justify-content: right !important;
        min-height: 40px;
        @media screen and (max-width: $break-xs) {
          min-height: 24px;
        }
      }


    }
  }
}

.progressContainer {
  justify-content: space-between;

  .progresslabel {
    font-size: smaller;
  }

  

  progress[value] {
    appearance: none;
    border: none;
    width: 100%; height: 5px;
    background-color: $grey;
    position: relative;
    box-shadow: none !important;
    margin: 0;
  }


  progress[value]::-webkit-progress-bar {
    background-color: $grey;
    border-radius: 3px;
    box-shadow: none !important;
  }

  progress[value]::-webkit-progress-value {
    position: relative;
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius:3px;
    animation: animate-stripes 5s linear infinite;
    box-shadow: none !important;
  }

  @keyframes animate-stripes { 100% { background-position: -100px 0; } }


  progress[value]::-webkit-progress-value:after {
    content: '';
    position: absolute;

    width:5px; height:5px;
    top:7px; right:7px;

    background-color: white;
    border-radius: 100%;
  }

  .progressBar::-webkit-progress-value {
    background-color: $green;
  }

  .progressBarYellow::-webkit-progress-value {
    background-color: $yellow;
  }
  .progressBarRed::-webkit-progress-value {
    background-color: red;
  }

  .progressInfo{
    margin: 5px;
  }

}