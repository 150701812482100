@import "../../variables";

.multiTitle {
  margin-right: 20px;
  display: inline-block;

  span {
    display: block;
    font-weight: bold;
    font-size: 13px;

    &.deviceProductGroup {
      word-break: break-all;
    }

    &.deviceType {
      font-size: 20px;
    }
  }
}
