@import "../../variables";

.picture{
    width: 100%;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.25px solid #707070;
    border-radius: 5px;
    
    .pictureLabel{
        font-size: 14px;
        font-family: "DINProBold", Arial, sans-serif;
        padding: 16px;
    }
}