@import "../../variables";

.contractsList {
  margin-top: -15px;

  .header {
    position: relative;
    grid-template-columns: repeat(3, 1fr);
    color: white;
    text-align: left;
    margin-bottom: 15px;
    padding: 0 !important;

    .filter {
      padding: $baseline 14px;
      line-height: 0;
      z-index: 999;
      border: 0;
    }

    .filterButton {
      position: absolute;
      right: 0;
      top: -20px;
    }
  }

  .stickyContractsListHeader {
    top: 25px;
    padding-top: 25px;
  }

  .mobileTopMargin {
    @media screen and (max-width: $break-md) {
      margin-top: 10px;
    }
  }

  .contractsListItem {
    font-family: "DINProRegular", Arial, sans-serif;
    font-size: 14px;
    word-break: break-word;

    border: 1px solid #929292;
    border-radius: 10px !important;
    color: #707070;

    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 1fr);

    height: auto;
    padding: 0 0 0 15px !important;
    margin: 5px 0 0 0 !important;

    @media screen and (max-width: $break-md) {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      height: auto;
    }

    .left {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .twoLines {
        position: relative;
        min-width: 50px;

        span {
          display: block;

          &:nth-child(2) {
            font-size: 10px;
            line-height: 6px;
            font-weight: 100;
          }
        }

        &.topSpace {
          top: -3px;
        }
      }

      .overflowContainer {
        overflow: hidden;
        margin-right: 0 $baseline;
        display: flex !important;
        flex-direction: column;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
      }
    }

    .priority {
      font-size: 1.5em;
      width: 45px;
      background: white;
      border-radius: 25px;
      text-align: center;
      margin: auto;
      display: block;

      > span {
        color: $orange;
      }
    }

    .icons {
      display: flex;
      width: 80px !important;
      gap: 20px;
      bottom: 10px;


      @media screen and (max-width: $break-md) {
        position: absolute;
        right: 10px;
        left: unset;
      }

      i {
        font-size: 1.5em;
      }
    }

    &.noItems {
      justify-content: center;
      opacity: 0.8;
      border: none;
      border-radius: 0;
    }
  }

  .leftCell {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-right: 0 $baseline;

    @media screen and (max-width: $break-md) {
      margin: 0;
      width: auto;
      flex-direction: row;
    }

    p {
      margin: 0;
    }
  }

  .centerCell {
    display: flex;
    flex-direction: column;
    //overflow: hidden;
    margin-right: 0 $baseline;

    //margin: 0;
    width: auto;
    flex-direction: column;

    @media screen and (max-width: $break-md) {
      margin: 0;
      width: auto;
      flex-direction: column;
    }
  }

  .rightCell {
    top: 0px;
    width: 150px;
    right: 10px;
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}


.fontBold {
  font-weight: bold;
  font-family: "DINProBold", Arial, sans-serif;
}

.icons {
  font-size: 17px;
  display: flex;
  flex-direction: row;
  width: 100% !important;
  max-width: 120px;
  color: #c9c9c9;

  @media screen and (max-width: $break-md) {
    justify-content: flex-end;
  }
}

.priorityBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 12px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;

  &.active {
    background-color: #29a645;
  }

  &.returned {
    background-color: $blue;
  }

  &.old {
    background-color: $grey;
  }
}