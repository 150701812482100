@import "../../variables";

.expensesPage {

  .expensesTab {
    padding-left: 19px;
    text-transform: uppercase;

    a {
      font-size: 14px !important;
      border-radius: 0 !important;

      &::after {
        border-radius: 0 !important;
      }
    }
  }
}