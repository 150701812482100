@import "../../variables";

.arsenalQuestionInput {

    .operatingHours {
        width: 100%;
    }
    
    .description {
        text-align: center;
        width: 100%;
        padding-bottom: $baseline;
        font-size: 1.5em;
    }
    
    > ArsenalButton {
        white-space: normal;
        
    }
    
    .comment {
        width: 100%;
        margin-top: $baseline;
        border: 0.25px solid $dark-gray;
        border-radius: 5px;
        font-size: 12px;
        font-family: "DINProRegular", Arial, sans-serif;

        &.danger {
            border-color: $danger;
        }
    }
    
    .commentLabel {
        margin-top: -$baseline;
        margin-bottom: $baseline;
        
        &.danger {
            color: $danger;
        }
    }
    
    .athenaeum-image-input-controlPanel{
        display: none;
    }
    
    .picture {
        width: 100%;
        position: relative;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.25px solid $dark-gray;
        border-radius: 5px;
        
        .pictureLabel{
            font-size: 14px;
            font-family: "DINProBold", Arial, sans-serif;
            padding: 16px;
        }

        > input {
            display: none;
        }

        > label {
            width: 100%;
            margin: 0;
            cursor: pointer;
            
            > ArsenalButton {
                pointer-events: none;
                cursor: pointer;
                height: 60px;
            }

            > div {
                width: 100%;
                border: 1px solid $orange;
                border-radius: 3px;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
                background-color: white !important;
                height: 200px;
            }
        }
        
        .actions {
            position: absolute;
            top: 0;
            right: 0;
            flex-direction: column;
            align-items: center;
            display: flex;
            background-color: white;
            margin: 3px;
            border-radius: 3px;
            padding: 0;
            border: 1px solid white;

            i {
                padding: $baseline/2;
                cursor: pointer;

                &.delete {
                    color: $orange;
                }

                &.preview {
                    color: $blue;
                }
            }
        }
    }

    .checkboxes {
        width: 100%;
        max-width: $break-xs;
        margin-top: 2*$baseline;
        font-size: 14px;

        .arsenalCheckbox {
            padding: 10px;
            border: 1px solid $light-gray;
            border-radius: 22px;
            width: 100%;
            height: fit-content!important;

            &:not(:first-child) {
                margin-top: $baseline;
            }
        }
    }
}

.btn-success{
    color: white!important;
}

.arsenalButtonStyles{
    background: white;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: none;
    border-radius: .35rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px!important;
    width: 32%;
    font-weight: 800;
    font-size: 14px!important;
    font-family: "DINProBold", Arial, sans-serif;
    padding: 0!important;
    
    i {
        width: 1.5rem!important;
        height: 1.5rem!important;
        font-size: 1.5rem;
        cursor: pointer;
    }
    @media screen and (min-width: $break-md) {
        &:hover {
            color: black !important;
        }
    }
}

.arsenalButtonOk{
    @extend .arsenalButtonStyles;
    
    i {
        color: $green;
    }
}

.arsenalButtonNeedRepairs{
    @extend  .arsenalButtonStyles;
    
    i {
        color: $orange;
    }
}

.arsenalButtonComment{
    @extend  .arsenalButtonStyles;

    i {
        color: $blue ;
    }
}

.checkIcon{
    color: $green!important;
}

.repairIcon{
    color: $orange;
}

.commentIcon{
    color: $blue;
}

.newCommentLabel{
    font-size: 12px;
    font-family: "DINProRegular", Arial, sans-serif;
    color: $grey;
    padding: 0;
    margin: 0;
    margin-top: 10px;

    &.danger {
        color: $danger;
        font-family: "DINProBold", Arial, sans-serif;
    }
}


.validationError{
    font-size: 12px;
    color: $danger;
    font-family: "DINProBold", Arial, sans-serif;
    padding: 0;
    margin-bottom: 10px;

}