@import "../../variables";

.reportMappings {

  .reportDefinition {
    background-color: rgba(24, 187, 62, 0.81) !important;
    border-radius: 0.25rem;
    padding: 0.5rem;
  }

  .category {
    flex: 0 0 75%;
    max-width: 75%;
    border-bottom: outset;
    margin: 1rem 0rem 1rem 0rem;
  }

  .margin {
    margin-left: 530px;
  }
}