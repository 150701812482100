@import "../../variables";

.arsenalButton {
  height: 60px;
  white-space: normal !important;
  outline: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  border-radius: 8px;
  font-size: 14px;
  
  :global {
    .actions-container {
      top: 58px;
    }

    .actions-icon {
      top: 16px !important;
      font-size: 24px !important;
    }
  }
}

.arsenalSecretButton {
  background-color: transparent !important;
  border-radius: 22px;
  padding: 2px;
  margin: 0;
  min-width: 8em !important;
  height: 2.5em;
}

.arsenalNoPointerEventsButton {
  pointer-events: none;
}

.arsenalEllipsisLabelButton {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
}

.btnSuccess{
  color: white!important;
  background-color: $green!important;
  
  i{
    color: white!important;
  }
}

.btnDanger{
  color: white!important;
  background: $orange!important;

  i{
    color: white!important;
  }
}

.whiteBg{
  background: white;
}

.btnBlue{
  color: white!important;;
  background: $blue!important;

  i{
    color: white!important;
  }
}

.whiteFont{
  color: white;
}

.fullWidth{
  width: 100%;
  height: 44px!important;
  font-family: "DINProBold", Arial, sans-serif;
}

.flexReverse{
  flex-direction: row-reverse;
  
  i{
    padding: 14px 0 14px 6px;
  }
}
