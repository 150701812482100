@import "../../variables";

.dateStyles {
  width: 80px;
}

.devicesList {
  margin: -15px 0;

  .header {
    position: relative;
    grid-template-columns: repeat(3, 1fr);

    .filter {
      padding: $baseline 14px;
      line-height: 0;
      z-index: 999;
    }
  }

  .stickyDeviceListHeader {
    top: 25px;
    padding-top: 25px;
  }

  .deviceListItem {
    border-radius: 10px;
    border: 1px solid #929292;
    font-family: "DINProRegular", Arial, sans-serif;
    color: #707070;
    margin: 9px 0;
    padding: 7px 7px 7px calc(12px + 7px);
    position: relative;
    font-size: 14px;
    word-break: break-word;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $break-md) {
      display: flex;
      flex-direction: row;
      font-size: 12px;
    }

    &.highPriority {
      background-color: red;
    }

    &.underInspection {
      background-color: $green;
    }

    &.underService {
      background-color: $green;
    }
    
    .paused {
      background-color: $yellow;
    }

    .left {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .twoLines {
        position: relative;
        min-width: 50px;

        span {
          display: block;

          &:nth-child(2) {
            font-size: 10px;
            line-height: 6px;
            font-weight: 100;
          }
        }

        &.topSpace {
          top: -3px;
        }
      }

      .overflowContainer {
        overflow: hidden;
        margin-right: 0 $baseline;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
      }
    }

    .priority {
      font-size: 1.5em;
      width: 45px;
      background: white;
      border-radius: 25px;
      text-align: center;
      margin: auto;
      display: block;

      > span {
        color: $orange;
      }
    }

    .rightCell {
      min-height: 75px;

      .iconsService {
        position: absolute;
        text-align: center;
        right: 15px;
        top: 10px;
        color: $orange;
        font-size: 1rem;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        i {
          font-size: 1.5em;
        }
      }

      .reportWrongForecastContainer {
        position: absolute;
        height: 33px;
        text-align: center;
        right: 10px;
        bottom: 10px;
        margin-top: 8px;

        :global(.athenaeum-button-button) {
          background-color: rgba(188, 157, 243, 0.31) !important;
          color: $purple !important;
        }
      }
    }
  }
}

.priorityBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 12px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;

  &.waitingForService {
    background: $orange;
  }

  &.forecast {
    background-color: $purple;
  }
  
  &.highPriority {
    background-color: $orange-hover;
  }

  &.inProgress {
    background-color: $green;
  }

  &.completed {
    background-color: $blue;
  }
  
  &.paused {
    background-color: $yellow;
  }
}

.leftCell {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 0 $baseline;

  @media screen and (max-width: $break-md) {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.fontBold {
  font-family: "DINProBold", Arial, sans-serif;
}

.filterButton {
  position: absolute;
  right: 0;
  top: -20px;
  z-index: 1000;
}

.filterButtonSettings {
  position: absolute;
  right: 60px;
  top: -20px;
  z-index: 1000;
}

.stickyDeviceListHeader {
  top: 45px;
  padding-top: 25px;
}

.iconHighPriority {
  position: absolute;
  right: 45px;
  top: 2px;
  color: #DC3545;
  font-size: 1.5rem;
}