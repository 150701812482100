@import "../../variables";

.devicesFiltersModal {
  .inline {
    > div {
      width: 50%;

      @media screen and (max-width: $break-md) {
        width: 100% !important;
      }
      
      > div {
        margin-bottom: 0;
        
        input {
          width: 100%;
        }
      }
    }
  }

  .form {
    height: 100%;
    position: static;
    
    @media(max-height: $break-md){
      margin-bottom: 100px;
    }
    
    .buttons {
      padding-top: 1rem;

      &.mobile {
        position: absolute;
        margin: 1rem 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

