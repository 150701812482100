@import "../../variables";

.badgeContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 1rem;
  flex-wrap: wrap;
}

.badge{
  position: relative;
  background: $orange;
  padding: 8px 28px 8px 8px;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
  font-family: "DINProBold", Arial, sans-serif;
  margin-bottom: 5px;
}

.removeIcon {
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 8px;
  display: flex;
  top: calc(50% - 0.5rem)
}

.addIcon {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color : white;
  background: $orange;
  border-radius: 5px;
  margin-bottom: 5px;
}