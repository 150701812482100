.searchResults {

  .divider {
    border-bottom: 1px solid #929292;
    height: 1px;
    width: 100%;
    margin: 1px 15px;
  }

  .listItemHeader {
    height: 70px;
    padding: 15px 15px 15px 15px !important;
    margin: 0 0 0 0 !important;
    width: 100%;

    .headerItem {
      padding: 15px !important;
    }
  }

  .actionButton {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    z-index: 100;
  }

  .nameColumn {
    word-wrap: break-word;
  }

  .itemList {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    padding-left: 15px;
    flex-wrap: wrap;
    flex-direction: column;

    .header {
      color: white;
      text-align: left;
      margin-bottom: 12px;
      padding: 0 !important;
    }

    .filter {
      padding: 0 !important;
      background-color: inherit;
      border: 0;

      &:hover {
        background-color: inherit !important;
        color: inherit !important;
      }
    }

    .listItem {
      border-radius: 10px !important;
      border: 1px solid #929292;
      //height: 70px;
      padding: 15px 15px 15px 15px !important;
      margin: 5px 15px 0 0 !important;
      width: 100%;
    }

    .relationItem {
      margin-top: 5px;
    }
  }
}