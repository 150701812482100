@import "../variables";

.pageContainer {
    padding: 0 !important;
    margin-bottom: 0 !important;
    position: relative;

    @media(max-width: $break-md) {
        margin-bottom: 100px!important;
    }

    > div {
        border-radius: 0 !important;
        height: 100%;
    }

    .alert {
        //@extend .dashboardAlert;
        max-width: 628px;
        margin-top: 0;
        margin-bottom: 4px;
        text-align: center;
        background: $transparent-dark-orange;
        border: none;
        cursor: default;
        color: white;
        border-radius: 5px;

        @media screen and (max-width: $break-md) {
            margin: 0 6px 8px 6px;
        }
    }

    .sticky {
        position: sticky !important;
        background: rgba(255, 255, 255, 1);
        z-index: 999;
        margin-left: -15px;
    }

    .stickyHeader {
        @extend .sticky;
        padding-top: 10px;
        margin-top: -15px;

        > div:first-of-type {
            margin-bottom: 0;
        }

        h4 {
            margin-left: 5px;
        }

        @media screen and (min-width: $break-md) {
            h4 {
                margin-left: 0;
            }
        }
    }

    .stickyFooter{
        position: sticky !important;
        bottom: 5px;
        z-index: 999;
    }

    .tabHeaderLink {
        padding: 6px 20px 6px 0 !important;

        &::after {
            border-radius: 0 !important;
        }
    }

    .tabContainerNavTabs {
        display: flex;
        width: 100%;
        justify-content: space-between;
        max-width: 250px;
        padding-left: 15px;
    }

    .stickyNavigationContainer {
        @extend .sticky;

        > div > ul:first-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .stickyDeviceListHeader {
        @extend .sticky;
        padding: 0 15px 10px 15px;
        margin: 0 -15px;
        box-shadow: 0 2px 2px -2px gray;
    }

    .deviceListPageTabs {
        .deviceListPageTab {
            padding-left: 19px;
            a {
                font-size: 14px !important;
            }
        }

        .deviceActions {

            margin-left: auto;
            width: auto;

            .infoButton {
            }
        }
    }

    .columnHeaderContainer {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        gap: 25px;
        padding:  7px 7px 7px calc(12px + 7px);

        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr 1.4fr 0.9fr;
        }

        .deviceListColumnHeader {
            font-family: "DINProLight", Arial, sans-serif;
            word-break: keep-all;

            &:first-of-type {
                text-align: start;
            }

            &:last-of-type {
                text-align: end;
            }
        }
    }


    .newDeviceListHeader {
        font-family: "DINProLight", Arial, sans-serif;
        word-break: keep-all;
        margin-top: 25px;
    }
}

.pageHeader {

    > div {

        padding-top: 0.2rem;
        padding-bottom: 0.2rem;

        > div {
            width: 100%;
            text-align: center;
        }
    }

    > hr {
        display: none;
    }
}

.leftPageHeader {
    @extend .pageHeader;
    margin-bottom: 0!important;
    > div {
        > div {
            text-align: left;

            h4 {
                margin-bottom: 0;
                line-height: 1.5;
            }
        }
    }
}

.leftPageHeader {
    text-align: right;
    margin-left: 4px;
    font-size: 18px;
    white-space: normal;
}

.rightPageHeader {
    text-align: right;
    margin-left: 4px;
    font-size: 18px;
    white-space: normal;
    font-family: "DINProLight", Arial, sans-serif;

    @media(min-width: $break-md) {
        min-width: 300px;
    }
}

.arsenalButton {
    height: 60px !important;
}

.inspectionActionButton{
    @extend .arsenalButton;
    margin-top: 0 !important;
    border-radius: 6px;
    text-align: center;
}

.reverseContent {
    flex-direction: row-reverse;
}

.infoButton {
    border-radius: 20px !important;
}

.wizardHeader {
    text-align: center;
    display: block;
    color: darkgray;
    padding-bottom: $baseline;
    margin-top: -$baseline;
    font-size: 1.1em;
}

.wizardDescription {
    text-align: center;
    display: block;
    padding: $baseline;
    font-size: 1.0em;
    word-break: break-all;
}

.extraInfoModal {
    .contentModal {
        overflow-y: auto;

        .info {
            font-size: 12px;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            > p {
                margin: 0;
            }
        }
    }
}

.arsenalNumberWidget {
    &.readonly {
        opacity: 0.5;

        > a {
            cursor: default;
        }
    }

    margin: $baseline auto;

    > a {

        &:hover {
            text-decoration: none;
        }

        > div:first-child {
            text-align: center;
            color: black;
            font-size: 1em;
        }

        > div:nth-child(2) {
            > div {
                display: flex;
                align-items: center;
                border: 1px solid $orange;
                border-radius: 3px;

                > div:nth-child(1),
                > div:nth-child(3) {
                    padding: $baseline;
                    background-color: $orange;
                    color: white;

                    &.disabled {
                        opacity: 0.5;
                        cursor: default;
                    }
                }

                > div:nth-child(2) {

                    width: 100%;

                    > div {

                        background-color: white;

                        span {
                            width: 100%;
                            display: block;
                            color: black;
                            font-size: 2em;
                            text-align: center;
                        }
                    }
                }

            }
        }

        > div:last-child {
            display: none;
        }

    }
}

.arsenalSwitchWidget {

    @media(max-width: $break-md) {
        justify-content: space-between;
        line-height: 1;
        margin-top: 15px !important;
    }
    &.readonly {
        opacity: 0.5;

        > a {
            cursor: default;
        }
    }

    position: relative;
    width: 100%;
    height: 32px;

    > div:first-child {

        @media(min-width: $break-md+1) {
            position: absolute;
            right: 50%;
        }


        label {
            font-size: 1.2em;
            padding-right: 0.5em !important;
        }
    }

    > div:last-child {
        @media(min-width: $break-md+1) {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: 0.5em;
        }

    }
}

.arsenalCheckboxes {
    margin: 8px auto;
    padding: 15px;
    height: auto;

    > div {
        justify-content: center;
    }
}

.arsenalDropdown {
    margin: 8px auto;
    padding-left: 15px;
    padding-right: 15px;

    > div {
        display: flex !important;
        justify-content: center;

        > label {
            display: flex;
            font-size: 1em;
            margin-bottom: 0;
        }

        > span {
            display: flex;
            margin-left: 4px;
        }

        > div > div > div:first-child {
            height: 47px;
            border: 1px solid #fe5000;

            > i {
                color: #fe5000;
                font-size: larger;
            }
        }
    }

    &:last-of-type {
        margin-bottom: 3*$baseline;
    }
}

.actionContainer{
    width: 80%;
    padding: 0 25px;

    @media(max-width: $break-md){
        width: 100%;
        padding: 0;
    }
}

.buttonContainer{
    margin-top: 20px;

    @media(max-width: $break-md) {
        padding: 0 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}

.actionWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigationModule {
}

.actionContainerGrid{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.actionContainerGridTwoButtons {
    display: grid;
    grid-template-columns: 1fr 0px 1fr;
    gap: 10px;
}

.navigationButton{
    position: relative;
    border-radius: 4px !important;
    font-family: "DINProMedium", Arial, sans-serif!important;
    font-size: 14px!important;
    height: 44px;
    padding-left: 40px!important;
    padding-right: 40px!important;
}

.progressModalButton{
    position: relative;
    border-radius: 4px !important;
    font-family: "DINProMedium", Arial, sans-serif!important;
    font-size: 14px!important;
    height: 44px;
}

.mb20{
    margin-bottom: 20px;
}

.buttonIcon{
    position: absolute;
    left: 10px;
}

.buttonIconRight{
    position: absolute;
    right: 10px;
}

.exitIcon{
    position: absolute;
    left: 10px;
    color: $orange !important;
}


.bigNavButton{
    font-size: 18px;
}

.progressButton{
    font-size: 25px !important;
}

.paddingLeft {
    padding-left: 8px;
}

.inspectionActionButtonStyles{
    @extend .arsenalButton;
    margin-top: 0 !important;
    color: $orange !important;
    border-radius: 6px;
    text-align: center;
    border-color: $orange !important;
}

.infoPageModal {
    .inheritWidth {
        width: inherit;
    }
}

.noPadding {
    padding: 0 !important;
    margin: 0 !important;
}

.filterWrapper{
    margin-bottom: 10px;
    padding: 0 10px;
}