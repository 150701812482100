@import "src/variables";

.progressCircle{
  background: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-bottom: 40px;
  border: 2px solid $orange;
  z-index: 1!important;
  position: relative;
  margin-left: 40px;
}

.progressWrapper{
  position: relative;
}

.progressLine{
  background: $orange;
  width: 7px;
  position: absolute;
  height: calc(100% - 40px);
  left: calc((25px/2) - 3.5px + 40px);
  z-index: 0;
}

.progressInfo{
  display: grid;
  grid-template-columns: 25px 1fr;
  gap: 40px;
  position: relative;
}

.progressText{
  padding-left: 40px;
  font-size: 16px;
  font-family: "DINProRegular", Arial, sans-serif;
}

.bold{
  font-family: "DINProBold", Arial, sans-serif
}

.stepCompleted{
  background: $orange;
}

.progressPhase{
  position: absolute;
  top: -24px;
  margin: 0;
  font-size: 18px;
  font-family: "DINProBold", Arial, sans-serif;
}