@import "../../../../variables.scss";

.wrapper{
  position: relative;
}

.menu{
  position: absolute;
  top: 50px;
  left: -80px;
  border: 1px solid $dark-gray;
  border-radius: 5px;
  background: white;
  width: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.switchContainer{
  display: flex;
  flex-direction: row;
}

.arsenalSwitchWidget {

  &.readonly {
    opacity: 0.5;

    > a {
      cursor: default;
    }
  }

  position: relative;
  width: 100%;
  height: 32px;

  > div:first-child {
    position: absolute;
    left: 66px;

    label {
      font-size: 1em;
      padding-right: 0.5em !important;
    }
  }

  > div:last-child {
    position: absolute;
    top: 0;
    right: calc(100% - 56px);
    margin-left: 0.5em;
  }
}

.deviceOrderMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
}

.switchButton{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-family: "DINProRegular", Arial, sans-serif;
    margin-top: -15px;  
  
    p {
      color: $grey;
      margin: 0;
    }
}

.bold{
  font-family: "DINProBold", Arial, sans-serif;
  color: black!important;
}

.textInputWrapper{
  position: relative;
}

.autoComplete{
    position: absolute;
    top: calc(1.5em + 0.75rem + 8px);
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid $grey-border;
    border-radius: 5px;
    z-index: 100;
    max-height: 200px;
    overflow-y: auto;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.375rem 0.75rem;
}