@import "../../variables";

.buttonWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkWrapper{
  background-color: $darker-white;
  border: solid 1px $grey-border;
  padding: 10px;
  border-radius: 8px;
  font-family: "DINProBold", Arial, sans-serif;
  margin-bottom: 10px;
}

.error{
  border-color: $orange;
}