.dashboard {
    .searchButtons {
        margin-top: 0 !important;
        margin-bottom: 30px;
    }

    button {
        margin-top: 4px !important;

        &:nth-last-child(3) {
            margin-top: 30px !important;
        }
    }

    .fileInput {
        > div > div > div > div {
            max-height: 60px !important;
        }
    }
}