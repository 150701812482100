.banner {
  padding: 20px;
  background-color: #ff9800;
  color: black;
  margin-bottom: 15px;
  margin-right: 25px;
  margin-left: 25px;

  border-radius: 10px;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}