@import "../../variables";

.dropdown {
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0 !important;
}

.dateContainer {
  margin: 0 !important;
  padding: 0 !important;
}