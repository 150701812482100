@import "../../variables";

.devices {

  .container {
    @media screen and (max-width: $break-md) {
      width: 100%;
    }

    display: inline-block;
  }

  .devicesGrid {
    @media screen and (max-width: $break-md) {
      width: 100% !important;
    }
  }

  .toolbar {
    width: 100%;

    > div {
      width: 100%;
    }

  }

}