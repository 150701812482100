.circle{
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;

  &.rent {
    background-color: #FFC107;
  }

  &.depo {
    background-color: #28A745;
  }
}