@import "../variables";

.newUI {
    .pageContainer {
        background-color: rgba(0, 0, 0, 0.50);
        padding: 0;

        > div {
            background: none !important;
        }

        .row {
            margin-bottom: 0 !important;
            padding: 8px 0 !important;
        }

        .col {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
        }

        .pageHeader {
            color: white;

            > div {
                padding: 0;
                text-align: left;

                h4 {
                    text-align: left;
                    font-size: 25px !important;
                }
            }
        }
    }

    .dropdown {
        width: 100%;

        :global {
            .selected-item {
                border: 0;
                border-radius: 10px;

                span {
                    &:first-letter {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .button {
        height: 60px !important;
        padding: 6px 15px;
        text-align: left;
        border-radius: 10px;
        border: none;
    }

    .label {
        border: none;
        height: 38px !important;
        display: flex;
        border-radius: 10px;
        justify-content: space-between;
        align-items: center;
        padding-right: 4px;
        font-size: 1rem !important;

        i {
            order: 2;
        }
    }

    .input {
        input {
            border-radius: 10px;
            border: none;
            padding: 6px 15px;

            &::placeholder {
                color: black;
            }
        }
    }

    .picturesInput {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &.center {
            justify-content: center;
        }

        input {
            display: none;
        }

        > div {
            display: flex;
            justify-content: center;
            margin: $baseline 0;

            .placeholder {
                display: flex;

                label {
                    position: relative;
                    background-color: #ffffff;
                    border-radius: 10px;
                    border: none;
                    margin: 0;
                    width: 175px;
                    height: 165px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        color: #414042;
                        font-size: 2rem;
                    }

                    .picture {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-size: contain !important;
                        background-repeat: no-repeat !important;
                        background-position: center !important;
                        background-color: white !important;
                        border-radius: 10px;
                    }
                }

                .actionsGroup {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    i {
                        padding: $baseline / 2;
                        cursor: pointer;

                        &.delete {
                            color: $orange;
                        }

                        &.rotateLeft {
                        }

                        &.rotateRight {
                        }

                        &.preview {
                            color: $blue;
                        }
                    }

                    &.left {
                        margin-left: $baseline;
                    }

                    &.right {
                        margin-right: $baseline;
                    }
                }
            }
        }
    }

    .navigation {
        background-color: white;
        border-radius: 10px;

        > div {
            color: #000000;
            padding: 8px 20px;
            width: 100%;
            background-color: inherit;
            border-radius: 10px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;

            .step {
                display: none;
            }

            .index {
                font-size: 20px;
                font-weight: normal;
                color: #BBBBBB;
                min-height: auto;

                input {
                    width: 40px;
                    padding: 0;
                    height: 30px;
                    text-align: center;
                    margin-top: 4px;
                }
            }

            .icon {
                margin-top: $baseline;
                margin-bottom: $baseline;
            }

            .title {
                font-size: 20px;

                > span {
                    padding-left: 0;
                }
            }
        }
    }

    .numberWidget {
        &.readonly {
            opacity: 0.5;

            > a {
                cursor: default;
            }
        }

        margin: $baseline auto;
        padding: 0;

        > a {

            &:hover {
                text-decoration: none;
            }

            > div:first-child {
                text-align: center;
                color: black;
                font-size: 1em;
            }

            > div:nth-child(2) {
                > div {
                    display: flex;
                    align-items: center;
                    border: none;
                    border-radius: 0;

                    > div:nth-child(1),
                    > div:nth-child(3) {
                        background-color: $orange;
                        color: white;
                        width: 100px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;

                        i {
                            font-size: 20px;
                        }

                        &.disabled {
                            opacity: 0.5;
                            cursor: default;
                        }
                    }

                    > div:nth-child(2) {

                        width: 100%;

                        > div {

                            background-color: white;
                            margin: 0 12px;
                            border-radius: 10px;
                            height: 40px;

                            span {
                                width: 100%;
                                display: block;
                                color: black;
                                font-size: 1rem;
                                line-height: 40px;
                                text-align: center;
                            }
                        }
                    }

                }
            }

            > div:last-child {
                display: none;
            }

        }
    }

    // Device service page
    .deviceService {
        > div {
            > div {
                &:nth-child(2) {
                    padding: 0 15px !important;
                }
            }
        }
    }

    // Returned device page
    .returnedDevicesPage {

        .deviceList {
            margin-bottom: 2rem;

            .header {
                color: white;
                text-align: left;
                margin-bottom: 12px;
                padding: 0 !important;
                position: relative;
            }


            .filter {
                padding: 0 !important;
                background-color: inherit;
                border: 0;

                &:hover {
                    background-color: inherit !important;
                    color: inherit !important;
                }
            }

            .deviceListItem {
                border-radius: 10px !important;
                height: 40px;
                padding: 0 15px !important;
                margin: 2.5px 0 !important;
            }
        }
    }
}

.summaryAlert {
    display: flex;
    width: 100%;
    justify-content: center;

    &.desktop {
        > div {
            width: 80%;
        }
    }

    > div > div {
        margin-top: 0;
        text-align: center;
    }
}

.marginTop {
    margin-top: 2px;
}

.subTitle {
    padding-left: 15px;
}

.bgWhite {
    background: white;
    margin: -15px;
    padding: 15px;
}

.deviceCounter {
    margin-left: 20px;
}