@import "../../variables";

.arsenalPicturesCarousel {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .pictures {
    position: relative;
    padding: 4 * $baseline;
    margin: auto;
    border: 1px solid $orange;
    border-radius: 3px;
    color: $grey;

    > i {
      font-size: 14em;
    }

    .picture {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-color: white !important;
    }
    input {
      display: none;
    }

    label {
      position: relative;
      border-radius: 3px;
      border: 1px solid $orange;
      margin: $baseline;

      i {
        color: $grey;
        padding: 2*$baseline;
      }
    }
    
    .actions {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: -4px;
      padding-top: 4px;

      i {
        padding: $baseline/2;

        &:nth-child(1) {
          color: $orange;
        }

        &:nth-child(2) {
          color: $blue;
        }
      }
    }
  }
  
  .pagination {
    width: 100%;
  }
}