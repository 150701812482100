@import "../../variables";

.picturesPreview {

  .pictureContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .comment {
    border: 1px solid $orange;
    border-radius: 3px;
    width: 100%;
    display: block;
    font-size: 1.4em;
    font-weight: normal;
    padding: 2*$baseline;
  }

  .commentPicture {
    position: relative;
    border-radius: 3px;
    border: 1px solid $orange;
    margin-top: $baseline;
    height: 100px;
    width: 100%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: white !important;
  }

  .observationComment {
    width: 100%;
    display: block;
    font-size: 1.4em;
    font-weight: normal;
    padding: 2*$baseline;
  }

  .table {
    width: 100%;
    font-size: 1.0em;

    td {
      width: 50%;
      padding: 2px 4px !important;
    }
  }
}