@import "../../variables.scss";

.totalOperatingHoursContainer {
  font-family: "DINProMedium", Arial, sans-serif;
  font-size: 1.2em;
  display: flex;
  align-content: center;
  width: 100%;
  justify-content: center;
}

.odometerIcon {
  font-size: 1.5rem;
  color: $orange;
  margin-right: 10px;
}