@import "../../../../variables";

.editDeviceStatusContainer {
  margin: 20px 15px;
  padding: 0 15px;

  @media (max-width: 768px) {
    margin: 0!important;
    padding: 0!important;
  }
}

.statusContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.titleWrapper {
  margin-bottom: 5px;
}

.buttonContainer {
  margin: 20px 15px;
  padding: 0 15px;

  @media (max-width: 768px) {
    margin: 0!important;
    padding: 0!important;
  }
}