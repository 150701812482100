@import "../../variables";

.photoContainer {
    padding-right: 5%;
    padding-left: 5%;
}

.picture {
    width: 100%;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.25px solid #707070;
    border-radius: 5px;

    .pictureLabel {
        font-size: 14px;
        font-family: "DINProBold", Arial, sans-serif;
        padding: 16px;
    }

    > input {
        display: none;
    }

    > label {
        width: 100%;
        margin: 0;
        cursor: pointer;


        > div {
            width: 100%;
            border: 1px solid $orange;
            border-radius: 3px;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-color: white !important;
            height: 200px;
        }
    }
}