@import "../../variables";

.buttonContainer {
    margin: 20px 15px;
    padding: 0 15px;

    @media (max-width: 768px) {
        margin: 0!important;
        padding: 0!important;
    }
}