@import "../../variables";

.iconsWrapper{
  width: 50px;
}

.devicesList {
  margin: -15px 0;
  .header {
    position: relative;
    grid-template-columns: repeat(3, 1fr);

    .filter {
      padding: $baseline 14px;
      line-height: 0;
      z-index: 999;
    }
  }

  .stickyDeviceListHeader {
    top: 25px;
    padding-top: 25px;
  }

  .mobileTopMargin{
    @media screen and (max-width: $break-md) {
      margin-top: 10px;
    }
  }

  .deviceListItem {
    border-radius: 10px;
    border: 1px solid #929292;
    font-family: "DINProRegular", Arial, sans-serif;
    color: #707070;
    margin: 9px 0;
    padding:  7px 7px 7px calc(12px + 7px);
    position: relative;
    font-size: 14px;
    word-break: break-word;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: $break-md) {
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }

    .left {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .twoLines {
        position: relative;
        min-width: 50px;

        span {
          display: block;

          &:nth-child(2) {
            font-size: 10px;
            line-height: 6px;
            font-weight: 100;
          }
        }

        &.topSpace {
          top: -3px;
        }
      }

      .overflowContainer {
        overflow: hidden;
        margin-right: 0 $baseline;
        display: flex!important;
        flex-direction: column;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
      }
    }

    .priority {
      font-size: 1.5em;
      width: 45px;
      background: white;
      border-radius: 25px;
      text-align: center;
      margin: auto;
      display: block;

      > span {
        color: $orange;
      }
    }

    .icons {
      display: flex;
      width: 80px!important;
      gap: 20px;
      bottom: 10px;


      @media screen and (max-width: $break-md) {
        position: absolute;
        right: 10px;
        left: unset;
      }

      i {
        font-size: 1.5em;
      }
    }

    &.noItems {
      justify-content: center;
      opacity: 0.8;
    }
  }
}

.leftCell{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 0 $baseline;

  @media screen and (max-width: $break-md) {
    margin: 0;
    width: calc(100% - 110px);
  }

  p {
    margin: 0;
  }
}

.rightCell{
  top: 10px;
  width: 110px;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}


.priorityBar{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 12px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;


  &.highPriority {
    background-color: #db3546;
  }

  &.underInspection {
    background-color: #29a645;
  }

  &.partlyCompleted {
    background-color: #FFC107;
  }

  &.inProgress {
    background-color: #28A745;
  }

  &.completed {
    background-color: $blue;
  }
  
  &.notCompleted{
    background-color: $grey;
  }
}

.fontBold{
  font-weight: bold;
  font-family: "DINProBold", Arial, sans-serif;
}

.icons{
  font-size: 17px;
  display: flex;
  flex-direction: row;
  width: 100%!important;
  max-width: 120px;
  color: #c9c9c9;

  @media screen and (max-width: $break-md) {
    justify-content: flex-end;
  }
}

.mechanicName {
  margin-top: 11px !important;
}

.warning{
  position: absolute;
  top: -2px;
  left: -25px;
  color: #DC3545;
  font-size: 18px;
}

.filterButton{
  position: absolute;
  right: 0;
  top: -20px;
}

.filterButtonSettings{
  position: absolute;
  right: 60px;
  top: -20px;
}

.done{
  color: #28A745;
}

.waitingForReturnInspection{
  background: #FE5018;
}

.marginTop {
  margin-top: 11px !important;
}

.dateText{
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: "DINProRegular", Arial, sans-serif;
  width: auto;
}

.dateStyles{
  position: relative;
  text-align: left;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: "DINProBold", Arial, sans-serif;
}