@import "../../variables";

.returnInspectionInvoiceDetails {

  .multiTitle {
    margin-right: 20px;
    display: inline-block;

    span {
      display: block;
      font-weight: bold;
      font-size: 13px;

      &.deviceProductGroup {
        word-break: break-all;
      }

      &.deviceType {
        font-size: 20px;
      }
    }
  }


  .deviceActions {

    margin-left: auto;
    width: auto;

    .infoButton {
    }

  }

  .container {

    .table {
      width: 100%;
      font-size: 1.0em;

      td {
        width: 50%;
        padding: 2px 4px !important;
      }
    }

    .info {
      padding-bottom: $baseline;

      p {
        margin-bottom: $baseline / 2;

        > span {
          margin-left: $baseline/2;
        }
      }
    }

    .newInvoice {
      display: flex;
    }

    .invoiceOtherResourcesCheckbox {
      max-width: 50%;
      padding-left: 15px;
      margin: 8px auto !important;
    }

    .value {
      display: flex;
      align-items: center;

      > div:first-child {
        margin-top: 20px;
        margin-left: $baseline;
      }

      &:last-of-type {
        margin-bottom: 2*$baseline;
      }
    }

    .newInvoiceDesktopDropdownStyle {
      max-width: 50%;
    }

    .desktopDropdownStyle {
      padding-right: 0 !important;
      padding-left: 31px !important;
      max-width: 50%;
    }

    .additionalExpenses {
      box-shadow: rgba(99, 99, 99, 0.5) 0 2px 8px 1px;
      border: 1px solid $renta-border;
      border-radius: 3px;
      padding-top: $baseline;
      padding-bottom: $baseline;
      margin-bottom: $baseline*2;

      &.desktop {
        max-width: 50%;
      }
    }

    .additionalExpensesTitle {
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 20px;
    }

    .additionalExpenseName {
      display: table;
      text-align: center;
      margin: auto auto;
      font-size: 16px;
      padding-top: 8px;
      padding-left: 64px;
      padding-right: 32px;
    }

    .additionalExpensesStepLink {
      margin-left: 8px;
      font-size: 18px;
      color: black;
      border-bottom: 0.25px solid #707070;
      font-family: "DINProMedium", Arial, sans-serif;
    }
  }
}