@import "../../variables";

.reportDefinitions {

  .copyButton{
    position: absolute;
    background: $blue;
    height: calc(100% - 8px);
    left: calc(100% - 45px);
    top: 0;
    border-radius: 3px;
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    width: 45px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spanContainer{
    max-width: calc(100% - 50px);
    font-size: 1.2rem;
  }
  
  .container {
  }

  .table {

    width: 100%;
    
    td {

      padding-top: $baseline / 2;
      padding-bottom: $baseline / 2;
      position: relative;
      
      span {
        background-color: $orange;
        border-radius: 3px;
        font-weight: bold;
        color: white;
        padding: $baseline;
        display: block;
        width: 100%;
        
        &.generic {
          background-color: $blue;
        }
      }
      
    }
    
  }
  
}