@import "../../variables";

.containerList{
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.pageDescription{
  font-size: 16px;
  font-family: "DINProLight", Arial, sans-serif;
  padding: 0 22px;
  margin: 5px 0;
  text-align: center;
}
