@import "../../variables";

.editVendorModal {

  .inline {
    > div {
      width: 100%;

      > div {
        margin-bottom: 0;

        input {
          width: 100%;
        }
      }
    }
  }

  .form {
    display: inline;
    height: 100%;
    position: relative;
    min-height: 180px;
  }
}