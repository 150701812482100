@import "../../variables";

.expandableButtonContainer{
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  justify-content: center;
  bottom: 50px;
}

.buttonIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  height: 44px;
  width: 44px;
  border: 1px solid $light-gray;
  border-radius: 4px;
  margin-left:7px;
}

.childrenContainer{
  width: calc(100% - 53px);
}

.container{
  margin-left: -15px!important;
  margin-right: -15px!important;
  box-shadow: 0 -5px 2px -5px #333;
  padding: 8px 12px 12px;
  width: calc(100% + 30px);
}

.expandableContent{
  margin-top: 10px;
  width: calc(100% - 53px);
}

.w100{
  width: 100%;
}