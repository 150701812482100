@import "../../variables";

.returnedDevicesPage {

  .stickyNavigationContainer {
    top: 106px;

    @media screen and (max-width: $break-md) {
      top: 91px;
    }
  }
  
  .pageHeader {
      margin-bottom: 0 !important;
  }
}