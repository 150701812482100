.noBordersGrid{
  border:0;
  background-color: transparent !important;

  thead{
    tr > th > div > span{
      font-family: "DINProMedium", sans-serif;
    }
  }
  tbody{
    td{
      border: 0 !important;
    }
    tr{
      border: 0 !important;
    }
  }
  th{
    border: 0 !important;
  }

}


.binIcon{
  display: flex;
  align-items: center;
  justify-content: center;
}