@import "../../../variables";

.editInvoiceModal {

  .inline {
    > div {
      width: 100%;

      > div {
        margin-bottom: 0;

        input {
          width: 100%;
        }
      }
    }
  }

  .final {
    margin-bottom: 1rem;
  }

  .form {
    height: 100%;
    position: relative;
    min-height: 180px;

    .buttons {
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}