@import "../../variables";

.home {
    padding: 0;
    
    .alert {
        // @extend .dashboardAlert;
        max-width: 628px;
        margin-top: 0;
        margin-bottom: 4px;
        text-align: center;
        background: $transparent-dark-orange;
        border: none;
        cursor: default;
        color: white;
        border-radius: 5px;

        @media screen and (max-width: $break-md) {
            margin: 0 6px 8px 6px;
        }
    }
}
