@import "../../variables";

.textBox{
  font-family: "DINProMedium", Arial, sans-serif;
  border: 1px solid $grey;
  width: 100%;
  overflow: auto;
  min-height: 30px;
  border-radius: 4px;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;

  outline: none;
}

.textBoxWrapper{
  margin-top: 10px;
}

.validationError{
  border-color: $orange;
}

.errorLabel{
  color: $orange;
}

.errorMessage{
  color: $orange
}