@import "../../variables";

.device {
  
  .backButton {
    margin-top: 5px;
  }

  .label {
    height: 38px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 4px;
    font-size: 1rem !important;
    color: #495057 !important;
  }

  .multiTitle {
    margin-right: 20px;
    display: inline-block;

    span {
      display: block;
      font-weight: bold;
      font-size: 13px;

      &.deviceType {
        font-size: 20px;
      }
    }
  }

  .failedDevice {
    span {
      color: $danger;
    }

    &.extraInfoDevice {
      span {
        cursor: pointer;
      }
    }
  }

  .checkbox {
      margin-top: $baseline;
      margin-bottom: $baseline !important;
  }

  .saveButton {
    margin-top: $baseline * 2;
  }

  .inspectionTypeInfo {
    left: 5px;

    i {
      font-size: 25px !important;
      top: 8px;
    }

    @media screen and (max-width: $break-md) {
      top: 12px;
      left: 10px;
    }
  }
}