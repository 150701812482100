
.deviceContractsContainer {
  width: 100% !important;

  .deviceContractRow {
    width: 100% !important;

    box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.1),
                0px 1px 0px 0px rgba(0, 0, 0, 0.1),
                0px 1px 0px 0px rgba(0, 0, 0, 0.1) !important;

    border-radius: 0px !important;
  }
}