@import "../../variables";

.reportMappings {

  .reportDefinitionContainer {
    margin-bottom: 20px;
    background: $light-gray;
    padding: 25px;
    border-radius: 0.25rem;

    .reportDefinitionRow{
      justify-content: space-between;
      @media (max-width: 800px) {
        flex-direction: column;
      }

      .rowHeader {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .rowHeader:last-of-type {
        flex-grow: 0;
      }
    }

    .reportDefinition {
      border-radius: 0.25rem;
      padding: 0.5rem;
    }

    .productGroupDropdown{
      min-width: 300px;
    }

    .removeMappingIcon {
      cursor: pointer;
      color: $orange;
    }

    .category {
      display: flex;
      border-radius: 0.5rem;
      background: white;
      justify-content: space-between;
      align-items: center;
      margin: 0.25rem 0rem 0.25rem 0rem;

      .categoryText {
        margin: 10px;
      }

      .categoryRemove {
        justify-content: flex-end;
      }
    }

    .emptyCategory {
      @extend .category;
      margin: 0.25rem 0.0rem 0.25rem 0.0rem;
      min-height: 40px;
    }
  }
}