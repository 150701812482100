@import "../../../variables";

.inspectionReportsList {
  min-width: 100%;

  .date {
    min-width: 100%;
    margin-bottom: $baseline/2;

    > div:first-child {
      width: 100%;

      > div {
        width: 100%;

        > input {
          border-color: $orange;
          width: 100%;
        }
      }
    }
  }

  .table {
    width: 100%;

    td {
      padding-top: $baseline / 2;
      padding-bottom: $baseline / 2;
    }

    .icon {
      > span {
        background-color: $orange;
        border-radius: 3px;
        font-size: 1.2em;
        font-weight: bold;
        color: white;
        padding: $baseline;
        display: block;
        width: 100%;
        cursor: pointer;
        text-align: center;
      }
    }

    .info {
      @extend .icon;
    }

    .info {
      width: 100%;

      > span {
        display: flex;
        justify-content: space-between;
        text-align: left;

        > div {
          display: inline-block;
        }

        .report {
          flex: 3;
        }

        .user {
          flex: 2;
          text-align: center;
        }

        .depo {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}