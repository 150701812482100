@import "../../../variables";

.annualInspectionDeviceList {

  .stickyDeviceListHeader {
    top: 194px !important;

    @media  screen and (max-width: 768px) {
      top: 223px !important;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .filter {
    padding: $baseline 14px;
    line-height: 0;
    margin: 0 0 auto auto;
  }
  
  .stickyDeviceListHeader {
    top: 151px;
    
    @media screen and (min-width: $break-md + 1) {
      top: 165px;
    }
  }

  .marginLeft {
    margin-left: 10px;
  }
  
  .remarksIcon{
    font-size: 1.5rem;
    color: $orange;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .annualInspectionDeviceListItem {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: "DINProLight", Arial, sans-serif;
    font-size: 12px;
    background-color: white;
    color: black;
    padding-right: $baseline;
    margin: $baseline / 2 0;
    border-radius: $radius;
    border: $renta-border;
    position: relative;

    .itemBlock {
      flex-basis: 100%;

      &:first-of-type {
        text-align: start;
      }

      &:nth-of-type(2) {
        text-align: center;
      }

      &:last-of-type {
        text-align: end;
      }
    }

    .verticalLineRed {
      border-left: 12px solid red;
    }

    .verticalLineBlue {
      border-left: 12px solid dodgerblue;
    }

    .verticalLineOrange {
      border-left: 12px solid orange;
    }

    .verticalLineTransparent {
      border-left: 12px solid white;
    }

    .verticalLine {
      border-radius: 6px;
      height: 100%;
      padding: $baseline / 3 $baseline;
    }

    .left {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .fourLines {
        position: relative;
        min-width: 50px;

        span {
          display: block;

          &:nth-child(2) {
            font-size: 12px;
            font-weight: 100;
            margin-bottom: 12px;
            margin-top: 8px;
          }

          &:nth-child(3) {
            font-size: 10px;
            font-weight: 100;
            margin-bottom: 12px;
            margin-top: 8px;
          }

          &:nth-child(4) {
            font-size: 12px;
            font-weight: 100;
            margin-bottom: 8px;
          }
        }

        &.topSpace {
          top: -3px;
        }
      }

      .deviceType {
        font-family: "DINProMedium", Arial, sans-serif;
        font-size: 14px;
      }
    }

    .priority {
      font-size: 1.5em;
      width: 45px;
      background: white;
      border-radius: 25px;
      text-align: center;
      margin: auto;
      display: block;

      > span {
        color: $orange;
      }
    }

    .icons {
      width: 80px;
      text-align: right;
      margin-right: 25px;

      i {
        font-size: 1.5em;
      }
    }

    &.noItems {
      justify-content: center;
      opacity: 0.8;
    }
  }
}