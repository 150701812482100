@import "../../variables";
 
.serviceExpenses {
  
  .container {
    @media screen and (max-width: $break-md) {
      width: 100%;
    }
    display: inline-block;
  }

  .expensesGrid {
    @media screen and (max-width: $break-md) {
      width: 100% !important;
    }
  }
  
  .toolbar {
    
    width: 100%;
    
    > div {
      width: 100%;
    }
    
  }
    
}