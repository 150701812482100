@import "../../variables";
 
.adminConsole {
  .timeoutTestContainer {
    .timeoutInput {
      > div > div > div > input {
        max-width: 160px;
      }
    }
    .timeoutButton {
      width: 100%;
      display: flex;
      justify-content: center !important;
      margin-top: 10px;
    }
  }

  .info {

    p {
      display: flex;

      span {
        min-width: 16rem;

        &:last-child {
          width: 100%;
          font-weight: bold;
          text-align: center;
          color: $text-black;
          max-width: 22rem;

          &.active {
            color: $green;
          }
        }
      }
    }

    @media screen and (max-width: $break-xlg) {
      @media screen and (max-width: 915px) {
        padding-top: 15px !important;
      }

      p {
        span {
          min-width: 13rem !important;
        }
      }
    }
  }
}
