@import "../../../variables";

.fuelTypes {

  .container {
    @media screen and (max-width: $break-md) {
      width: 100%;
    }

    display: inline-block;
  }

  .fuelTypesGrid {
    @media screen and (max-width: $break-md) {
      width: 100% !important;
    }
  }

  .toolbar {

    width: 100%;

    > div {
      width: 100%;
    }
  }
}