@import "../../variables";

.wrapper{
  position: relative;
}

.menu{
  position: absolute;
  top: 50px;
  left: -80px;
  border: 1px solid $dark-gray;
  border-radius: 5px;
  background: white;
  width: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.switchContainer{
  display: flex;
  flex-direction: row;
}

.arsenalSwitchWidget {

  &.readonly {
    opacity: 0.5;

    > a {
      cursor: default;
    }
  }

  position: relative;
  width: 100%;
  height: 32px;

  > div:first-child {
    position: absolute;
    left: 66px;

    label {
      font-size: 1em;
      padding-right: 0.5em !important;
    }
  }

  > div:last-child {
    position: absolute;
    top: 0;
    right: calc(100% - 56px);
    margin-left: 0.5em;
  }
}