@import "../../variables";

.errorCodeWrapper{
  position: relative;
  border-radius: 10px;
  border: 1px solid #929292;
  color: black;
  font-size: 14px;
  margin-bottom: 12px;
  padding: 12px 12px 12px 15px;

  p {
    margin: 0;
    font-family: "DINProLight", Arial, sans-serif;
  }
  
  .bold{
    font-family: "DINProBold", Arial, sans-serif
  }
}

.errorCodeTitle{
  font-family: "DINProBold", Arial, sans-serif!important;
  width: calc(100% - 50px);
}

.buttonIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  height: 44px;
  width: 44px;
  border: 1px solid $light-gray;
  border-radius: 4px;
  margin-left:7px;
  background: $orange;
  color: white;
}

.buttonContainer{
  position: absolute;
  right: 12px;
  top: 12px;
}

.extendedContentGrid{
  display: grid;
  grid-template-columns: 90px 1fr;
  margin-top: 20px;
}

.informationBar {  
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 12px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;

  &.rent {
    background-color: #FFC107;
  }

  &.depo {
    background-color: #28A745;
  }
  
}