@import "../../variables";

.buttonWrapper{
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: solid 1px $grey;
  border-radius: 20px;
  max-width: 90px;
  min-width: 90px;
  background-color: white;
  font-size: 1rem;
  font-family: "DINProMedium", Arial, sans-serif;
  cursor: pointer;
  
  &.wrapperYes{
    background-color: $green;
    color: white;
  }

  &.wrapperNo{
    background-color: $orange;
    color: white;
  }

  &.wrapperSkip{
    background-color: $grey;
  }
}

.iconWrapper{
  display: flex;
  align-items: center;
  width: 30px;
  
}

.icon{
  font-size: 1.25rem;
  color: $green;
  
  &.selectedIcon {
    color : $white;
  }
}

.noIcon{
  font-size: 1.25rem;
  color: $orange;

  &.selectedIcon {
    color : $white;
  }
}

.skipIcon{
  font-size: 1.25rem;
  color: black;
}


.disabled{
  opacity: 0.5;
  cursor: not-allowed;
}
