@import "../../../../variables.scss";

.wrapper {
  border: 1px solid $grey;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  font-family: "DINProRegular", Arial, sans-serif;
  color: #707070;
  
  p {
    margin: 0;
  }
}

.focused {
  border: 2px solid $orange;
  background: rgba(254, 80, 0, 0.1);
}

.bold{
  color: black;
  font-family: "DINProBold", Arial, sans-serif;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px
}

.textInformation{
  width: 80%;
}

.buttonIcon{
  font-size: 1.5rem;
}