/* Breakpoints 
   TODO: utilize bootstrap mixins with breakpoints
*/
$break-xxs: 310px;
$break-xs: 480px;
$break-sm: 640px;
$break-md: 768px;
$break-lg: 1024px;
$break-xlg: 1280px;

/* Sizes */
$baseline: 8px;
$radius: 6px;

/* Colors */
$orange: rgb(254, 80, 0);
$transparent-orange: rgba(254, 80, 0, 0.9);
$dark-orange: rgb(234, 60, 0);
$transparent-dark-orange: rgba(234, 60, 0, 0.9);
$light-gray: rgb(227, 227, 227);
$lighter-gray: rgb(238, 238, 238);
$grey: #8D8D8D;
$dark-gray: rgb(65, 64, 66);
$white: rgb(255, 255, 255);
$transparent-white: rgba(255, 255, 255, 0.95);
$text-black: #333;
$danger: #a94442;
$warning: #ffc107;
$blue: #0693FA;
$dark-blue: #0000aa;
$green: #009522;
$dark-green: #006600;
$separator-color: rgba(0, 0, 0, 0.1);
$approved: #28a74520;
$declined: #a9444220;
$processed: #F1F3FFFF;
$completed: #17A2B811;
$new: #ffb34760;
$dark-gray: #707070;
$yellow: #FFC135;
/* Hover colors */
$orange-hover: $transparent-dark-orange;
$button-hover: $light-gray;
$border-hover: $grey;
$orange-transparent: rgb(254, 80, 0, 0.5);
/* Shadows */
$renta-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
$purple: #6f42c1;
$darker-white: #f8f9fa;
$grey-border: #e1e1e1;

/* Renta border */
$renta-border: 1px solid rgba(0, 0, 0, 0.15);
$renta-border-orange: 2px solid #FE5000;

.font-renta {
    font-family: "DINProMedium";
}