@import "../../variables";

.arsenalNavigation {
  margin-left: -15px;
  width: 100vw;
  max-width: calc(100% + 30px);
  
  > div {
    background-color: $orange;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    color: white;
    padding: $baseline;
    margin: auto;
    text-align: center;
    min-width: 40%;

    .step {
      font-size: 1.5em;
      font-weight: bold;
    }

    .index {
      font-size: 1.8em;
      font-weight: bold;
      min-height: 38px;

      input {
        width: 40px;
        padding: 0;
        height: 30px;
        text-align: center;
        margin-top: 4px;
      }
    }

    .icon {
      font-size: 2rem;
    }

    .title {
      font-size: 1.5em;
      font-weight: bold;

      > span {
        padding-left: $baseline;
      }
    }
  }
  
}