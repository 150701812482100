@import "../../variables";

.stepTitle {
    margin-bottom: 16px;
    text-align: center;
    font-size: 1.5em;
}

.desktopDropdownStyle {
  max-width: 50%;
}

.numberWidgetExtraText {
  margin: 8px auto;
  padding-left: 15px;
  
  @media screen and (min-width: $break-md) {
    max-width: 50%;
  }
}