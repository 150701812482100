@import "../../variables";
 
.userManagement {
  
  .passwordInfo {
    padding: 0;
    width: 100%;
    display: flex;
    max-height: calc(100% + 1px);
    opacity: 1;
  }
  
  .invitations {
    min-height: $baseline * 2;
    margin-bottom: 15px;
  }
}