@import "../../variables";

.photoContainer {
  padding-right: 5%;
  padding-left: 5%;

  .comment {
    width: 100%;
    margin-top: $baseline;
    margin-bottom: $baseline;
  }
}

.removeBtn {
  width: 100% !important;
  padding-top: 0;
  padding-bottom: 30px;
  margin-top: 0;
}

.subtitle {
  width: 100%;
}

.exclamationMark {
  float: left;
  color: $warning;
  padding-right: 8px;
}

.label {
  height: 38px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4px;
  font-size: 1rem !important;
  color: #495057 !important;

  i {
    order: 2;
  }
}

.contractDropdown {
  width: 100% !important;
  padding: 0;
}

.dateInput {
  margin-top: 15px;
  width: 100%;

  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}