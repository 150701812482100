@import "../../variables";

.reportPreview {
  
  .help {
    display: flex;
    flex-direction: column;
    margin-top: -15px !important;
    
    .clickStepText {
      width: 100%;
      font-size: 1.2em;
      padding-bottom: 2*$baseline;
      text-align: center;
    }
    
    button {
      margin-right: 0;
    }
    
    .inspectionInfo {
      display: flex;
      justify-content: space-between;
    }
  }
  
}