@import "../../variables";

.container {
  width: 100%;
  display: inline-block;
}

.toolbar {
  width: 100%;

  > div {
    width: 100%;
  }
}