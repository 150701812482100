@import "../../variables";

.arsenalPagination {
  
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: $baseline;
  width: 80%;
  border: 1px solid $orange;
  border-radius: 3px;

  i {
    padding: 4px 2 * $baseline;
    background-color: $orange;
    color: white;
  }
  
  .cursor {
    cursor: pointer;
  }

  div {
    width: 100%;

    span {
      width: 100%;
      text-align: center;
      display: block;
      font-size: 1.6em;
    }
  }

}