@import "../../variables";

.servicePreview {

  > div > div > div {
    margin-bottom: -5px !important;
  }

  .faultsContainer {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .faultChecks {
      margin-top: 1rem !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;

      > div > h5 {
        margin-bottom: 0;
      }
    }
  }

  .sectionContainer {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    :global {
      .row {
        &:first-of-type {
          padding-top: 0;
        }
      }
    }

    > div > div > h5 {
      margin-bottom: 0;
    }

    .serviceItem {
      margin-top: 1rem !important;
      margin-bottom: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .date {
        width: 100%;

        :global {
          .react-datepicker-wrapper {
            width: 100%;
          }
        }
      }

      .comment {
        width: 100%;
      }

      .label {
        height: 38px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 4px;
        font-size: 1rem !important;
        color: #495057 !important;

        i {
          order: 2;
        }
      }

      .checkbox {
        margin-bottom: 1rem !important;
      }
    }
  }

  button {
    margin-right: 0;

    &:first-of-type {
      margin-top: 2rem !important;
    }
  }
}