@import "../../variables";

.device {
    
    .alert {
        padding: 20px;
        background-color: #ff9800;
        color: black;
        margin-bottom: 15px;
        margin-right: 25px;
        margin-left: 25px;

        border-radius: 10px;
    }

    /* The close button */
    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    /* When moving the mouse over the close button */
    .closebtn:hover {
        color: black;
    }
    
    .multiTitle {
        margin-right: 20px;
        display: inline-block;
        
        span {
            display: block;
            font-weight: bold;
            font-size: 13px;
            
            &.deviceProductGroup {
                word-break: break-all;
            }
            
            &.deviceType {
                font-size: 20px;
            }
        }
    }
    
    .inspectionActions {
        display: flex;
        flex-direction: row;
        gap: 0 7px;
        margin-bottom: 7px;
        
        .labelPaddingLeft {
            padding-left: 8px;
        }
    }
    
    .deviceActions {
        
        margin-left: auto;
        width: auto;
        
        .infoButton {
        }
        
        .priorityButton {
            text-transform: uppercase;
            &.isPriority {
                background-color: $orange !important;
                color: $white !important;
            }
        }
        
    }
    
    .failedDevice {
        span {
            color: $danger;
        }
        
        &.extraInfoDevice {
            span {
                cursor: pointer;
            }
        }
    }
    
    .container {
        padding-bottom: $baseline;
        
        .picture {
            width: 100%;
            height: 180px;
            display: flex;
            justify-content: center;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
        }

        .image {
            display: flex;
            justify-content: center;
        }
    }

    .info {
        padding-bottom: $baseline;

        .col{
            padding-left: 0!important;
            padding-right: 0!important;
        }
        
        p {
            margin-bottom: $baseline / 2;
            
            mark {
                color: $danger;
                font-weight: bold;
            }
        }
        
        div{
            margin-bottom: $baseline / 2;
            display: flex;
            flex-direction: row;
            align-items: center;

            mark {
                color: $danger;
                font-weight: bold;
            }

            .downloadButtonWrapper {
                margin-left: 0.5rem;
            }
        }
    }

    .table {
        width: 100%;
        font-size: 1.0em;

        td {
            padding: 2px 4px !important;
        }
    }
    
    .danger {
        background-color: #fe5000;
        color: white;
        padding-left: 2px;
        padding-right: 2px;
        border-radius: 4px;
    }
}