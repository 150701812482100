@import "../../variables";

.picturesEvents {

  .pictureContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .comment {
    border: 1px solid $orange;
    border-radius: 3px;
    width: 100%;
    display: block;
    font-size: 1.4em;
    font-weight: normal;
    padding: 2*$baseline;
  }

  .commentPicture {
    position: relative;
    border-radius: 3px;
    border: 1px solid $orange;
    margin-top: $baseline;
    height: 100px;
    width: 100%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: white !important;
  }
}