@import "../../variables";

.actionBtn{
  height: 42px!important;
  border-radius: 8px;
  font-size: 14px;
  font-family: "DINProBold", Arial, sans-serif;
}

.pageRow{
  margin-top: 0!important;
}

.title{
  font-family: "DINProMedium", Arial, sans-serif;
  font-size: 14px;
}

.textContainer{
  margin-top: 30px;
  margin-bottom: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  font-size: 14px;
  font-family: "DINProRegular", Arial, sans-serif;
  white-space: break-spaces;
}

.checkIcon{
  color: $green;
  width: 40px;
  height: 40px;
  font-size: 25px;
}

.failedIcon{
  color: $orange;
  width: 40px;
  height: 40px;
  font-size: 25px;
}

.maintenanceIcon {
  color: $orange;
  width: 40px;
  height: 40px;
  font-size: 25px;

  &.passed {
    color: $green;
  }
}