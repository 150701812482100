@import "src/variables";

.deviceInfoPanel {

  .multiTitle {
    margin-right: 20px;
    display: inline-block;
    cursor: pointer;
    
    .exclamationMark {
      float: left;
      color: $warning;
      padding-right: 8px;
    }

    span {
      display: block;
      font-weight: bold;
      font-size: 13px;

      &.deviceProductGroup {
        word-break: break-all;
      }

      &.deviceType {
        font-size: 20px;
      }
    }
  }

  .deviceActions {

    margin-left: auto;
    width: 112px;

    .infoButton {
    }

    .locationButton {
      text-transform: uppercase;
    }

    .priorityButton {
      text-transform: uppercase;

      &.isPriority {
        background-color: $orange !important;
        color: $white !important;
      }
    }

    //noinspection CssInvalidPropertyValue
    .priorityButton {
      text-wrap: balance;
    }

  }

  .failedDevice {
    span {
      color: $orange;
    }

    &.extraInfoDevice {
      span {
        cursor: pointer;
      }
    }
  }

  .container {
    padding-bottom: $baseline;

    .picture {
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: center;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }

    .image {
      display: flex;
      justify-content: center;
    }
  }

  .info {
    padding-bottom: $baseline;

    p {
      margin-bottom: $baseline / 2;

      mark {
        color: $danger;
        font-weight: bold;
      }
    }
  }

  .table {
    width: 100%;
    font-size: 1.0em;

    td {
      padding: 2px 4px !important;
      word-break: break-word;
    }
  }

  .danger {
    background-color: $orange;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 4px;
  }
}
