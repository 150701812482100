@import "../../variables";

.OperatingHoursTable {
  position: relative;
  border-radius: 6px;
  opacity: 1;
  border: 0.25px solid #707070;
  font-family: "DINProLight", Arial, sans-serif;
  width: 100%;
  background: #FFFFFF;
  border-collapse: unset!important;
  border-spacing:0;
}

.operatingHoursTitle{
  font-family: "DINProBold", Arial, sans-serif;
  border-collapse:collapse;
  box-sizing: border-box;
}

.titleTd{
  padding: 10px!important;
}

.orangeRecord {
  background: $orange-transparent;

  &:hover {
    background: $orange;
    color: white;
    cursor: pointer;
    font-family: "DINProBold", Arial, sans-serif;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
  
.normalRecord {

  &:hover {
    background: $grey;
    cursor: pointer;
    font-family: "DINProBold", Arial, sans-serif;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  } 
}
