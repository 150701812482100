@import "../../variables";

.resourceInput {
  &:last-child {
    margin-bottom: 30px;
  }
  
  span {
    text-transform: uppercase;
  }
}

.desktopDropdownStyle {
  max-width: 50%;
}

.resourceContainer{
  margin-top: 35px;

  .numberWidgetExtraText {
    margin: 8px auto;
    padding-left: 15px;

    @media screen and (min-width: $break-md) {
      max-width: 50%;
    }
  }
}