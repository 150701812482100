@import "../../../variables";

.faultCheckContainer {
    width: 100%;
    
    .faultCheck {
        background: $orange;
        padding: 15px;
        border-radius: 10px;
        color: white;
        cursor: pointer;
        
        i {
            color: white;
        }
        
        &.readonly {
            opacity: 0.5;
        }
    }
}