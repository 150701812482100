.multiTitle {
  margin-left: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
  cursor: pointer;

  span {
    display: block;
    font-weight: bold;
    font-size: 13px;

    &.deviceProductGroup {
      word-break: break-all;
    }

    &.deviceType {
      font-size: 20px;
    }
  }
}