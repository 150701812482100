@import "../../variables";

.arsenalPageRow {

    padding: $baseline 15px;
    padding-bottom: 0 !important;
    display: flex;
    align-content: center;

    > button {
        margin-left: auto;
        margin-right: auto;
        height: 80px;
    }    
}