
.reportWrongForecastModal {

  .form {
    height: 100%;
    position: static;

    .buttons {
      padding-top: 1rem;

      &.mobile {
        position: absolute;
        margin: 1rem 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}