@import "../../variables";

  .annualInspectionPage {

    .annualInspectionsTabs {

      .annualInspectionTab {
        margin-left: 20px;
        
        a {
          font-size: 14px !important;
        }
      }

      .tabHeaderLink {
        padding: 6px 20px 6px 0 !important;
        &::after {
          border-radius: 0 !important;
        }
      }

      .tabHeaderLinkMobile {
        padding: 6px 0 6px 0 !important;
        &::after {
          border-radius: 0 !important;
        }
      }

      .tabContainerNavTabs {
        display: flex;
        width: 100%;
        justify-content: space-around;
      }

      .tabContainerNavTabsMobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      
    }
    
    .stickyHeaderTop {
      top: 45px;
    }
    
    .stickyNavigationContainer {
      top: 142px;
    }

    .colorLegendInfo {
      
      .circle {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
      }
      .redCircle {
        background-color: red;
      }
      .blueCircle {;
        background-color: dodgerblue;
      }
      .orangeCircle {
        background-color: orange;
      }
    }
    
    @media screen and (max-width: $break-md) {
      .colorLegendInfo {
        flex-direction: column;
        align-items: stretch;
        padding-left: 10px;
      }

      .stickyNavigationContainer {
        top: 171px;
      }
      }


  }

  